exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actualize-8-js": () => import("./../../../src/pages/actualize8.js" /* webpackChunkName: "component---src-pages-actualize-8-js" */),
  "component---src-pages-bomb-af-js": () => import("./../../../src/pages/bomb-af.js" /* webpackChunkName: "component---src-pages-bomb-af-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mindful-oat-js": () => import("./../../../src/pages/mindful-oat.js" /* webpackChunkName: "component---src-pages-mindful-oat-js" */),
  "component---src-pages-ubc-planetary-healthcare-js": () => import("./../../../src/pages/ubc-planetary-healthcare.js" /* webpackChunkName: "component---src-pages-ubc-planetary-healthcare-js" */),
  "component---src-pages-wala-js": () => import("./../../../src/pages/wala.js" /* webpackChunkName: "component---src-pages-wala-js" */),
  "component---src-pages-zoomak-js": () => import("./../../../src/pages/zoomak.js" /* webpackChunkName: "component---src-pages-zoomak-js" */)
}

