import "./src/styles/application.scss";

import React from "react";
import { AnimatePresence } from "framer-motion";
import LayoutProvider from "./src/components/LayoutProvider";
export const wrapPageElement = ({ element }) => (
  <LayoutProvider>
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </LayoutProvider>
);
