import React, { createContext, useState } from "react";

export const LayoutContext = createContext(null);

export default function LayoutProvider({children}) {
  const [ pageDirection, setPageDirection ] = useState('right');

  const values = {
    pageDirection, setPageDirection
  }

  return (
    <LayoutContext.Provider value={values}>
      {children}
    </LayoutContext.Provider>
  )
}
